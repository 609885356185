import React from 'react';

function App() {
  return (
    <div>
      <p>tinius.dev</p>
    </div>
  );
}

export default App;
